@import "@/styles/variables.scss";

/* Stat Figure Styling */

.StatFigure {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: $spaceLg;
  margin-bottom: $spaceXL;
  font-size: $fontMd;
  font-weight: $fontSemibold;
  padding: 0 $spaceLg;
  @media (max-width: $tablet) {
    font-size: $fontBase;
  }
  h3 {
    font-size: $fontGraphic;
    font-weight: $fontSemibold;
    color: $tertiary;
    background: linear-gradient(
      90deg,
      rgb(246, 142, 95) 0%,
      rgba(78, 2, 80, 0.5) 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    font-size: $fontSmall;
  }
}
