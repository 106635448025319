@import "@/styles/variables.scss";

$translateAmt: 5px;

.RotatingWords {
  // each span is positioned on top of each other
  // animation exposes them at different intervals via opacity & translate
  position: relative;
  span {
    position: absolute;
    width: calc(100vw - 2 * $spaceXL);
    opacity: 0;
    overflow: hidden;
    margin-left: $spaceSm;
    // rotating words animations
    animation: rotateWord 6s ease infinite;
    // give gradient fill to text
    background: linear-gradient(
      90deg,
      rgb(246, 142, 95) 0%,
      rgba(78, 2, 80, 0.5) 10%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 1.5s;
    }
    &:nth-child(3) {
      animation-delay: 3s;
    }
    &:nth-child(4) {
      animation-delay: 4.5s;
    }
  }
}

//* Animations *//
@keyframes rotateWord {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
    transform: translateY($translateAmt);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  28% {
    opacity: 0;
    transform: translateY(-$translateAmt);
  }
  100% {
    opacity: 0;
  }
}
