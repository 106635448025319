@import "@/styles/variables.scss";

/* About Pario Styling */
$translateAmt: 5px;

.AboutPario {
  @include flexCol;
  justify-content: flex-end;
  position: relative;
  height: 330px;
  width: 330px;
  padding: $spaceSm;
  font-size: $fontMd;
  font-weight: $fontSemibold;
  p {
    font-size: $fontSmall;
    margin-bottom: $spaceSm;
    &:first-of-type {
      margin-top: $spaceSm;
    }
  }
  .absolute {
    position: absolute;
  }
  &:nth-of-type(even) {
    @media (max-width: $tablet) {
      border: 1px solid $secondary20;
      border-radius: 4px;
    }
    color: $secondary;
    justify-content: flex-start;
    .absolute {
      bottom: 0;
      right: 0;
    }
  }
  &:nth-of-type(odd) {
    div {
      margin-bottom: 2 * $spaceLg;
    }
    p {
      position: absolute;
      width: calc(100vw - 2 * $spaceXL);
      opacity: 0;
      overflow: hidden;
      bottom: 10;
      width: 90%;
      // rotating words animations
      animation: rotateWord 20s ease infinite;
      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 5s;
      }
      &:nth-child(3) {
        animation-delay: 10s;
      }
      &:nth-child(4) {
        animation-delay: 15s;
      }
    }
  }
  &:nth-of-type(2) {
    .absolute {
      left: 0;
    }
  }
  &:first-of-type {
    background-color: rgb(252, 158, 79, 0.5);
    .absolute {
      top: 0;
      right: 0;
    }
  }
  &:nth-of-type(3) {
    background-color: $tertiary05;
    .absolute {
      top: 0;
      right: 0;
    }
  }
}

//* Animations *//
@keyframes rotateWord {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
    transform: translateY($translateAmt);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  28% {
    opacity: 0;
    transform: translateY(-$translateAmt);
  }
  100% {
    opacity: 0;
  }
}
