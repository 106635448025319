@import "./variables.scss";

//* Pario Landing Page Styles *//
.Landing {
  // container styles for landing page
  @include flexCol;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  /* Utilities */

  .flex {
    @include flex;
    gap: $spaceSm;
  }

  .center {
    @include center;
  }

  .flexCol {
    @include flexCol;
    gap: $spaceLg;
  }

  /* Typography */
  h1,
  h2 {
    font-weight: $fontSemibold;
    font-size: $fontDisplay;
  }

  h1, h2, h3, h4, h5, p {
    line-height: unset;
  }

  h1 {
    @media (max-width: $tablet) {
      font-size: $fontXL;
    }
  }

  h2 {
    @media (max-width: $tablet) {
      font-size: $fontLg;
    }
  }

  // viewport page container
  .page {
    @include flexCol;
    // justify-content: space-between;
    width: 100%;
    min-height: 100vh;
    padding: $spaceLg $spaceXL;
    background-color: $primary;
    color: $secondary;
    &.secondary {
      padding: 0;
      min-height: calc(100vh - 2 * $spaceXL);
      .title {
        width: 100%;
        @include flex;
        @include center;
      }
    }
  }
  // Hero Image container
  .imageContainer {
    margin: 0 auto;
    .heroImage {
      border-radius: 6px;
      width: 300px;
      height: 300px;
      @media (min-width: $tablet) {
        border-radius: $spaceSm;
        width: 600px;
        height: 600px;
      }
    }
  }

  .gifContainer {
    @include flex;
    margin: $spaceXL 0;
    .zygote {
      width: 100%;
      object-fit: cover;
      filter: hue-rotate(220deg) saturate(80%);
    }
  }

  .gridCol2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    gap: 3 * $spaceXL;
    min-height: 100vh;
    @include colWidth;
    @media (max-width: $tablet) {
      // two column grid on laptop+
      grid-template-columns: 1fr;
      margin-top: 2 * $spaceXL;
      gap: 0;
    }
    // first grid column
    div {
      @include flexCol;
      height: 50%;
      min-height: 500px;
      max-width: 300px;
      align-items: center;
      justify-content: space-evenly;
      @media (min-width: $tablet) {
        font-size: $fontXL;
        max-width: 600px;
        align-items: unset;
      }
    }
    p {
      font-size: $fontLg;
      @media (max-width: $tablet) {
        font-size: $fontMd;
        text-align: center;
      }
    }
  }

  .gridCol4 {
    display: grid;
    grid-template-columns: 1fr;
    margin: $spaceXL auto;
    @include colWidth;
    @media (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $laptop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .maternalHealthGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: $spaceXL auto 0 auto;
    @include colWidth;
    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
    .heroFigure {
      @include flexCol;
      @include center;
      padding: 0 $spaceMd;
      background-color: rgb(252, 158, 79, 0.5);
      @media (max-width: $tablet) {
        text-align: center;
      }
      h3 {
        font-size: 2 * $fontGraphic;
        font-weight: $fontSemibold;
      }
      p {
        font-size: $fontLg;
        margin: $spaceLg 0;
      }
      div {
        margin-top: $spaceSm;
      }
    }
  }
}
